<template>
    <div class="aa-areas">
        <div class="aa-areas-left mt-1">
            <div class="titulo aa-text-h1 text-start">
                Áreas
            </div>
            <span class="aa-areas-line"></span>
            <div class="aa-areas-intro text-start my-1">
                Encontrarás información de los distintos departamentos, sus asignaturas y demás información necesaria.
            </div>
            
        </div>
        <div class="aa-areas-right">
            <swiper
                :slides-per-view="3"
                :loop="false"
                :space-between="0"
                :breakpoints="{
                '640': {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                '768': {
                    slidesPerView: 3,
                    spaceBetween: 0,
                },
                '1024': {
                    slidesPerView: 8,
                    spaceBetween: 0,
                },
                }"
                :modules="modules"
                class="default-slider"
            >
            <swiper-slide v-for="area in areasList" :key="area.id" class="areas-slider">
                <PortadaListadoArea :datos="area" />
            </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import { Swiper } from 'swiper/vue/swiper'
import { SwiperSlide } from 'swiper/vue/swiper-slide'
import { Pagination, Navigation } from "swiper"
import 'swiper/swiper.min.css'

import { defineAsyncComponent, onMounted } from 'vue'
import useAreas from '../composables/useAreas'


export default {
    components: {
        Swiper,
        SwiperSlide,
        PortadaListadoArea: defineAsyncComponent( () => import('../components/Area/PortadaListadoArea.vue')),
    },
    setup(){
        const { areasList, isLoading, cargaAreasListado, creaAnima } = useAreas()
        cargaAreasListado()

        onMounted(()=>{
            creaAnima()
        })

        return {
            modules: [ Pagination, Navigation ],
            isLoading,
            areasList,
            
        }
    }

}
</script>

<style lang="sass" scoped>
.aa-areas
    $root:&
    margin-top: 96px
    &-left
        position: relative
        visibility: hidden
        margin-top: 105px
        
    
    &-line
        position: absolute
        width: 170px    
        height: 31px
        background-color: #18d580
        left: 0
        top: 8px
        z-index: -1
    
    &-intro
        line-height: 145%
        padding-left: 2rem
        padding-right: 2rem
        @include breakpoint(lg)
            padding-left: 3rem
            padding-right: 3rem
    @include breakpoint(md)
        display: flex
        height: 550px
        &-left
            width: 30%
        &-right
            width: 70%
            cursor: grab
    
</style>